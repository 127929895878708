<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <!-- 基本信息 -->
          <div class="form-label-base">基本信息</div>
          <el-form-item label="车辆类型" prop="sanitationCarManageVO.carType">
            <el-cascader
              v-model="form.sanitationCarManageVO.carType"
              placeholder="请选择"
              :options="$enums_hw.carType.list"
              :show-all-levels="false"
              @change="carTypeChange"
              filterable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="车牌号码" prop="sanitationCarManageVO.plateNumber">
            <el-input
              v-model="form.sanitationCarManageVO.plateNumber"
              placeholder="请输入"
              maxlength="25"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="权属类型">
            <el-select v-model="form.sanitationCarManageVO.belongType" placeholder="请选择">
              <el-option
                v-for="item in $enums_hw.belongType.list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属标段" prop="sanitationCarManageVO.bidId">
            <el-cascader
              v-model="form.sanitationCarManageVO.bidId"
              placeholder="请选择"
              :options="bidList"
              :show-all-levels="false"
              filterable
              @change="bidIdChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="动力类型">
            <el-select v-model="form.sanitationCarManageVO.powerType" placeholder="请选择">
              <el-option
                v-for="item in $enums_hw.powerType.list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发动机号">
            <el-input v-model="form.sanitationCarManageVO.engineNumber" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="所在项目组">
             <el-input v-model="form.sanitationCarManageVO.projectGroupId" placeholder="请输入" disabled></el-input>
          </el-form-item>
          <el-form-item label="车架号">
            <el-input v-model="form.sanitationCarManageVO.vin" placeholder="请输入" maxlength="18"></el-input>
          </el-form-item>
          <el-form-item label="使用状态" carStatus>
            <el-select v-model="form.sanitationCarManageVO.carStatus" placeholder="请选择">
              <el-option
                v-for="item in $enums_hw.carStatus.list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属企业">
            <el-input v-model="form.sanitationCarManageVO.companyName" placeholder="请输入" disabled></el-input>
          </el-form-item>
          <el-form-item label="上传车辆照片" style="width: 100%">
            <ayl-img-upload
              v-model="imageUrls"
              :imgWidth="'120px'"
              :imgHeight="'120px'"
              :imgListLength="1"
              :imgSize= "10"
            ></ayl-img-upload>
            <!-- <ayl-upload-single-image v-model="form.sanitationCarManageVO.carImageUrls"></ayl-upload-single-image> -->
          </el-form-item>
          <!-- 油箱参数 -->
          <div class="form-label-base">油箱参数</div>
          <el-form-item label="油量监测" prop="sanitationCarManageVO.hasFuel">
            <el-select v-model="form.sanitationCarManageVO.hasFuel" placeholder="请选择" @change="oilMonitoringClick">
              <el-option
                v-for="item in optionsValue12"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <template v-if="form.sanitationCarManageVO.hasFuel===1">
            <el-form-item label="油箱形状" prop="carFuelInfoVO.fuelTankType">
              <el-select v-model="form.carFuelInfoVO.fuelTankType" placeholder="请选择" @change="tankShapeClick">
                <el-option
                  v-for="item in $enums_hw.fuelTankType.list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>

          <el-form-item label="长(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem !== null" prop="carFuelInfoVO.length">
            <el-input v-model="form.carFuelInfoVO.length" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="宽(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem === 0" prop="carFuelInfoVO.width">
            <el-input v-model="form.carFuelInfoVO.width" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="高(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem === 0" prop="carFuelInfoVO.height">
            <el-input v-model="form.carFuelInfoVO.height" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="直径(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem ===1 || tankShapeItem ===2" prop="carFuelInfoVO.diameter">
            <el-input v-model="form.carFuelInfoVO.diameter" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="长直径(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem === 3" prop="carFuelInfoVO.longDiameter">
            <el-input v-model="form.carFuelInfoVO.longDiameter" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="短直径(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem === 3" prop="carFuelInfoVO.shortDiameter">
            <el-input v-model="form.carFuelInfoVO.shortDiameter" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="颈高(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem === 3" prop="carFuelInfoVO.neckHeight">
            <el-input v-model="form.carFuelInfoVO.neckHeight" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="油杆长(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem !== null" prop="carFuelInfoVO.fuelRodLength">
            <el-input v-model="form.carFuelInfoVO.fuelRodLength" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="油箱厚度(cm)" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem !== null" prop="carFuelInfoVO.thickness">
            <el-input v-model="form.carFuelInfoVO.thickness" placeholder="请输入" type="number" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="最大标定值" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem !== null" prop="carFuelInfoVO.maxCalibration">
            <el-input v-model="form.carFuelInfoVO.maxCalibration" placeholder="请输入" type="number" maxlength="6"></el-input>
          </el-form-item>
          <el-form-item label="容积修正参数" v-if="form.sanitationCarManageVO.hasFuel===1 && tankShapeItem !== null && tankShapeItem !== 3" prop="carFuelInfoVO.volumeRevisionFactor">
            <el-input v-model="form.carFuelInfoVO.volumeRevisionFactor" placeholder="请输入" type="number" minlength="5" maxlength="10"></el-input>
          </el-form-item>

          <!-- 保险信息 -->
          <div class="form-label-base">保险信息</div>
          <el-form-item label="保险公司">
            <el-input v-model="form.sanitationInsuranceInfoVO.insuranceCompany" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="保险类型">
            <el-input v-model="form.sanitationInsuranceInfoVO.insuranceType" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="保险单号">
            <el-input v-model="form.sanitationInsuranceInfoVO.insuranceNumber" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="保险有效期">
            <el-date-picker
              v-model="date1"
              type="daterange"
              unlink-panels
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="购买价格">
            <el-input
              v-model="form.sanitationInsuranceInfoVO.purchasePrice"
              placeholder="请输入"
              maxlength="11"
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
          <el-form-item label="代理人">
            <el-input v-model="form.sanitationInsuranceInfoVO.agent" placeholder="请输入"></el-input>
          </el-form-item>
          <!-- 行驶证信息 -->
          <div class="form-label-base">行驶证信息</div>
          <el-form-item label="行驶证所有人">
            <el-input v-model="form.sanitationDrivingLicenseInfoVO.owner" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="行驶证有效期">
            <el-date-picker
              v-model="date2"
              type="daterange"
              unlink-panels
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="行驶证图片" style="width: 100%">
            <ayl-img-upload
                  v-model="form.sanitationDrivingLicenseInfoVO.driverLicenseImageKeyList"
                  :imgWidth="'120px'"
                  :imgHeight="'120px'"
                  :imgListLength="1"
                  :imgSize= "10"
            ></ayl-img-upload>
            <!-- <ayl-upload-single-image v-model="form.img1"></ayl-upload-single-image> -->
          </el-form-item>

          <!-- 年检信息 -->
          <div class="form-label-base">年检信息</div>
          <el-form-item label="年检编号">
            <el-input v-model="form.sanitationCarInspectVO.inspectId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="年检日期">
            <el-date-picker
              v-model="form.sanitationCarInspectVO.inspectDate"
              type="date"
              value-format="timestamp"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="到期日期">
            <el-date-picker
              v-model="form.sanitationCarInspectVO.inspectExpire"
              type="date"
              value-format="timestamp"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="年检费用">
            <el-input
              v-model="form.sanitationCarInspectVO.inspectFee"
              placeholder="请输入"
              maxlength="11"
            >
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
          <!-- 保养信息 -->
          <div class="form-label-base">保养信息</div>
          <el-form-item label="最近保养日期">
            <el-date-picker
              v-model="form.sanitationCarMaintainVO.lastMaintainDate"
              type="date"
              value-format="timestamp"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="保养周期">
            <el-input
              v-model="form.sanitationCarMaintainVO.maintainPeriod"
              placeholder="请输入"
              maxlength="11"
            >
              <span slot="suffix">天</span>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose('/district-management/vehicle-management/vehicle-management')">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [{ name: "编辑车辆" }],
      submitLoading: false,
      tankShapeItem: null,
      originalForm: null,
      form: {
        // 基本信息
        sanitationCarManageVO: {
          carType: null, //车辆类型
          plateNumber: null, //车牌号码
          belongType: null, //权属类型
          bidId: null, //所属标段
          powerType: null, //动力类型
          engineNumber: null, //发动机号
          projectGroupId: null, //所在项目组
          vin: null, //车架号
          carStatus: null, //使用状态
          companyName: null, //所属企业
          hasFuel: null, //油量监测
          carImageUrls: null //图片
        },
        // 油箱参数
        carFuelInfoVO: {
          fuelTankType: null, //油箱形状
          length: null,
          width: null,
          height: null,
          diameter: null,
          longDiameter: null,
          shortDiameter: null,
          neckHeight: null,
          fuelRodLength: null,
          thickness: null,
          maxCalibration: null,
          volumeRevisionFactor: null,
        },
        // 保险信息
        sanitationInsuranceInfoVO: {
          insuranceCompany: null, //保险公司
          insuranceType: null, //保险类型
          insuranceNumber: null, //保险单号
          endTime: null,
          startTime: null,
          purchasePrice: null, //购买价格
          agent: null //代理人
        },
        // 年检信息
        sanitationCarInspectVO: {
          inspectId: null, //年检编号
          inspectDate: null, //年检日期
          inspectExpire: null, //到期日期
          inspectFee: null //年检费用
        },
        // 保养信息
        sanitationCarMaintainVO: {
          lastMaintainDate: null, //最近保养日期
          maintainPeriod: null //保养周期
        },

        // 行驶信息
        sanitationDrivingLicenseInfoVO: {
          owner: null, //行驶证所有人
          endTime: null,
          startTime: null,
          driverLicenseImageKeyList: [] //行驶证图片
        },
        
        
        img1: null
      },
      date1: [],
      date2: [],
      imageUrls: [],
      // 所属标段下拉
      bidList: [],
      // 所在项目组下拉
      optionValue7: [
        { value: "项目1", label: "项目1" },
        { value: "项目2", label: "项目2" },
        { value: "项目3", label: "项目3" }
      ],
      // 油量监测下拉
      optionsValue12: [
        { value: 0, label: "未启用" },
        { value: 1, label: "启用" }
      ],
      // 必填字段校验
      rules: {
        "sanitationCarManageVO.carType": {
          required: true,
          message: "请选车辆类型",
          trigger: "change"
        },
        "sanitationCarManageVO.plateNumber": [
          { required: true, message: "请输入车牌号码", trigger: "blur" }
        ],
        "sanitationCarManageVO.bidId": {
          required: true,
          message: "请选择所属标段",
          trigger: "change"
        },
        "sanitationCarManageVO.hasFuel": {
          required: true,
          message: "不能为空",
          trigger: "change"
        },
        "carFuelInfoVO.fuelTankType": {required: true, message: '请选择油箱形状', trigger: 'change'},
        "carFuelInfoVO.length": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.width": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.height": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.diameter": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.longDiameter": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.shortDiameter": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.neckHeight": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.fuelRodLength": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.thickness": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isOneDecimal}],
        "carFuelInfoVO.maxCalibration": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isInteger}],
        "carFuelInfoVO.volumeRevisionFactor": [{required: true, message: '请正确输入参数', trigger: 'blur'},{validator: this.$validator.isForsDecimal}],
      }
    };
  },
  created() {
      this.originalForm = this.form?this.$_.cloneDeep(this.form):{};
  },
  methods: {
    carTypeChange(arr) {
      this.form.sanitationCarManageVO.carType = arr[arr.length - 1];
    },
    async bidIdChange(arr) {
      this.form.sanitationCarManageVO.bidId = arr[1];
      let bidInfoData = await this.$api_hw.common_getBidInfo({
        id:  arr[1]
      })
      this.form.sanitationCarManageVO.projectGroupId = bidInfoData.projectGroupId
    },

    async submit() {
      let params = {
        sanitationCarDetailInfoVO: {
          carFuelInfoVO: this.form.carFuelInfoVO,
          sanitationCarInspectVO: this.form.sanitationCarInspectVO,
          sanitationCarMaintainVO: this.form.sanitationCarMaintainVO,
          sanitationCarManageVO: this.form.sanitationCarManageVO,
          sanitationDrivingLicenseInfoVO: this.form
            .sanitationDrivingLicenseInfoVO,
          sanitationInsuranceInfoVO: this.form.sanitationInsuranceInfoVO
        }
      };

      // 时间段处理
      params.sanitationCarDetailInfoVO.sanitationInsuranceInfoVO.startTime =
        this.date1.length > 0 ? this.date1[0] : null;
      params.sanitationCarDetailInfoVO.sanitationInsuranceInfoVO.endTime =
        this.date1.length > 0 ? this.date1[1] : null;
      // 时间段处理
      params.sanitationCarDetailInfoVO.sanitationDrivingLicenseInfoVO.startTime =
        this.date2.length > 0 ? this.date2[0] : null;
      params.sanitationCarDetailInfoVO.sanitationDrivingLicenseInfoVO.endTime =
        this.date2.length > 0 ? this.date2[1] : null;

      if (this.imageUrls.length !==0) {
        params.sanitationCarDetailInfoVO.sanitationCarManageVO.carImageUrls = this.imageUrls[0]
      }

      if (params.sanitationCarDetailInfoVO.sanitationCarManageVO.hasFuel === 1) {
        params.sanitationCarDetailInfoVO.carFuelInfoVO.maxCalibration = parseInt(this.form.carFuelInfoVO.maxCalibration);
      }

      await this.$api_hw.vehicleManagement_updateByPlateNumber(params);
    },

    onSubmit(isGoNext) {
      let _this = this;
      if (_this.submitLoading) return; //防止重复提交
      _this.$refs.form.validate(async valid => {
        if (!valid) return;
        _this.submitLoading = true;
        try {
          await _this.submit();
          _this.$notify({
            title: "成功",
            message: `${_this.nav[0].name}成功`,
            type: "success"
          });
          //BUS.$emit(BUSEVENT.makeEvent("refresh", _this.$route.query.from), _this.$route.query.id);
          BUS.$emit(BUSEVENT.REFRESH_VEHICLE_MANAGEMENT);
          _this.onClose("/district-management/vehicle-management/vehicle-management");
          // BUS.$emit(BUSEVENT.REFRESH_VEHICLE_MANAGEMENT);
          // _this.onClose("../" + self.$route.query.from);
        } catch (e) {
          // console.info(e);
        } finally {
          _this.submitLoading = false;
        }
      });
    },

    onSubmitAndNext() {
      this.onSubmit();
    },
    onClose(toPath) {
      if (toPath && typeof toPath == "string")
          // for 标签页
          BUS.$emit(BUSEVENT.CLOSE_NAV, [this.$route, toPath]);
      else {
          BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
      // for 弹窗
      this.$emit("onClose");
    },
    oilMonitoringClick(val){
      if(val === 1){
        this.form.sanitationCarManageVO.hasFuel = val
      }else {
        this.form.sanitationCarManageVO.hasFuel = val
        this.tankShapeItem = null,
        this.form.carFuelInfoVO.length = null,      //长
        this.form.carFuelInfoVO.width = null,       //宽
        this.form.carFuelInfoVO.height = null,       //高
        this.form.carFuelInfoVO.diameter = null,    //直径
        this.form.carFuelInfoVO.longDiameter = null,    //长直径
        this.form.carFuelInfoVO.shortDiameter = null,     //短直径
        this.form.carFuelInfoVO.neckHeight = null,        //颈高
        this.form.carFuelInfoVO.fuelRodLength = null,   //油杆长
        this.form.carFuelInfoVO.thickness = '0.3',    //油箱厚度
        this.form.carFuelInfoVO.maxCalibration = null,   //最大标定值
        this.form.carFuelInfoVO.volumeRevisionFactor = null  //容积修正参数
      }
    },

    //油箱
    tankShapeClick(val){ 
      if(val === 0){
        this.tankShapeItem = val
      }else if(val === 0){
        this.tankShapeItem = val,
        this.form.carFuelInfoVO.length = null,      //长
        this.form.carFuelInfoVO.width = null,       //宽
        this.form.carFuelInfoVO.height = null,       //高
        this.form.carFuelInfoVO.diameter = null,    //直径
        this.form.carFuelInfoVO.longDiameter = null,    //长直径
        this.form.carFuelInfoVO.shortDiameter = null,     //短直径
        this.form.carFuelInfoVO.neckHeight = null,        //颈高
        this.form.carFuelInfoVO.fuelRodLength = null,   //油杆长
        this.form.carFuelInfoVO.thickness = '0.3',    //油箱厚度
        this.form.carFuelInfoVO.maxCalibration = null,   //最大标定值
        this.form.carFuelInfoVO.volumeRevisionFactor = null  //容积修正参数
      }else if(val === 1){
        this.tankShapeItem = val,
        this.form.carFuelInfoVO.length = null,      //长
        this.form.carFuelInfoVO.width = null,       //宽
        this.form.carFuelInfoVO.height = null,       //高
        this.form.carFuelInfoVO.diameter = null,    //直径
        this.form.carFuelInfoVO.longDiameter = null,    //长直径
        this.form.carFuelInfoVO.shortDiameter = null,     //短直径
        this.form.carFuelInfoVO.neckHeight = null,        //颈高
        this.form.carFuelInfoVO.fuelRodLength = null,   //油杆长
        this.form.carFuelInfoVO.thickness = '0.3',    //油箱厚度
        this.form.carFuelInfoVO.maxCalibration = null,   //最大标定值
        this.form.carFuelInfoVO.volumeRevisionFactor = null  //容积修正参数
      }else if(val === 2){
        this.tankShapeItem = val,
        this.form.carFuelInfoVO.length = null,      //长
        this.form.carFuelInfoVO.width = null,       //宽
        this.form.carFuelInfoVO.height = null,       //高
        this.form.carFuelInfoVO.diameter = null,    //直径
        this.form.carFuelInfoVO.longDiameter = null,    //长直径
        this.form.carFuelInfoVO.shortDiameter = null,     //短直径
        this.form.carFuelInfoVO.neckHeight = null,        //颈高
        this.form.carFuelInfoVO.fuelRodLength = null,   //油杆长
        this.form.carFuelInfoVO.thickness = '0.3',    //油箱厚度
        this.form.carFuelInfoVO.maxCalibration = null,   //最大标定值
        this.form.carFuelInfoVO.volumeRevisionFactor = null  //容积修正参数
      }else if(val === 3 ){
        this.tankShapeItem = val,
        this.form.carFuelInfoVO.length = null,      //长
        this.form.carFuelInfoVO.width = null,       //宽
        this.form.carFuelInfoVO.height = null,       //高
        this.form.carFuelInfoVO.diameter = null,    //直径
        this.form.carFuelInfoVO.longDiameter = null,    //长直径
        this.form.carFuelInfoVO.shortDiameter = null,     //短直径
        this.form.carFuelInfoVO.neckHeight = null,        //颈高
        this.form.carFuelInfoVO.fuelRodLength = null,   //油杆长
        this.form.carFuelInfoVO.thickness = '0.5',    //油箱厚度
        this.form.carFuelInfoVO.maxCalibration = null,   //最大标定值
        this.form.carFuelInfoVO.volumeRevisionFactor = null  //容积修正参数
      }
    }
  },
  async mounted() {
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
    let Odata = await this.$api_hw.vehicleManagement_getByTenantId({});
    this.$set(
      this.form.sanitationCarManageVO,
      "companyName",
      Odata.company
    );


    let carNub = decodeURIComponent(this.$route.query.id);
    let formData = await this.$api_hw.vehicleManagement_getCarInfoByPlateNumber(
      {
        plateNumber: carNub
      }
    );
    this.form = formData;
    // 图片绑定处理
    if(this.form.sanitationCarManageVO.carImageUrls !== null) {
      this.imageUrls = [this.form.sanitationCarManageVO.carImageUrls]
    } else {
      this.imageUrls = []
    }
    if(this.form.sanitationDrivingLicenseInfoVO.driverLicenseImageKeyList === null) {
      this.form.sanitationDrivingLicenseInfoVO.driverLicenseImageKeyList = []
    }
    
    // 时间段日期绑定处理
    if(this.form.sanitationInsuranceInfoVO.startTime === null) {
      this.date1 = []
    }else {
      this.date1 = [
        this.form.sanitationInsuranceInfoVO.startTime,
        this.form.sanitationInsuranceInfoVO.endTime
      ];
    }
    
    if(this.form.sanitationDrivingLicenseInfoVO.startTime === null) {
      this.date2 = []
    } else {
      this.date2 = [
        this.form.sanitationDrivingLicenseInfoVO.startTime,
        this.form.sanitationDrivingLicenseInfoVO.endTime
      ];
    }
    
    
    if(formData.sanitationCarManageVO.hasFuel === 1){
      this.form.sanitationCarManageVO.hasFuel = formData.sanitationCarManageVO.hasFuel
      this.oilMonitoringClick(formData.sanitationCarManageVO.hasFuel)
      this.tankShapeClick(formData.carFuelInfoVO.fuelTankType)
      //this.form.carFuelInfoVO.maxCalibration = (formData.carFuelInfoVO.maxCalibration).toString()
    }else{
      this.form.sanitationCarManageVO.hasFuel = formData.sanitationCarManageVO.hasFuel
    }
  }
};
</script>

<style lang='sass' scoped>
.form-label-base
  border-left: 2px solid rgb(26, 188, 156)
  padding-left: 8px

/deep/ .el-form
  display: flex
  flex-wrap: wrap
  .el-form-item__content
    width: 230px
  .form-label-base
    width: 100%
  .el-form-item--mini .el-form-item__content,
  .el-cascader--mini,
  .el-select
    width: 230px
</style>
